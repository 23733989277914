import { ResourceInterface } from '@/shared/component/resource.interface';
import { IAuditLog } from '@/shared/model/audit-log.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import axios, { AxiosInstance } from 'axios';

const baseApiUrl = 'api/private/cron-job-resource';

export default class CronJobLogsService implements ResourceInterface {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios;
  }
  retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  find(id: number): Promise<IAuditLog> {
    return new Promise<IAuditLog>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(id: number): Promise<IAuditLog> {
    throw new Error('Method not implemented.');
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(entity: any): Promise<IAuditLog> {
    throw new Error('Method not implemented.');
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(entity: any): Promise<IAuditLog> {
    throw new Error('Method not implemented.');
  }
}
