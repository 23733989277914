// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import * as config from './shared/config/config';
import vuetify from '@/shared/config/vuetify';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import AuditsService from './admin/audits/audits.service';
import CronJobLogsService from './admin/cron-job/cron-job-logs.service';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import JsonViewer from 'vue-json-viewer';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';

import LoginService from './account/login.service';
import AccountService from './account/account.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import AlertService from '@/shared/alert/alert.service';
import TranslationService from '@/locale/translation.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import StorageService from '@/shared/storage/storage.service';
import RegisterService from '@/account/register/register.service';
import ActivateService from '@/account/activate/activate.service';
import SigninService from '@/account/signin/signin.service';
import ResetPasswordService from '@/account/reset-password/reset.password.service';
import ProfileService from '@/account/profile/profile.service';
import AsyncService from '@/shared/async/async.service';
import UpgradeService from '@/account/upgrade/upgrade.service';
import StripePlanService from '@/shared/data/stripe/stripe-plan.service';
import AgreementService from '@/account/agreement/agreement.service';
// import AcceptanceService from '@/shared/acceptance/acceptance.service';

/* tslint:disable */

import ApiKeyService from '@/entities/api-key/api-key.service';
import ApplicationUserService from '@/entities/application-user/application-user.service';
import ElbSubscriptionService from '@/entities/elb-subscription/elb-subscription.service';
import OrganizationService from '@/entities/organization/organization.service';
import PlanService from '@/entities/plan/plan.service';
import PlanFeatureService from '@/entities/plan-feature/plan-feature.service';
import ProductService from '@/entities/product/product.service';
import ProductVersionService from '@/entities/product-version/product-version.service';
import SettingService from '@/entities/setting/setting.service';
import WebHookVersionService from '@/entities/web-hook-version/web-hook-version.service';
import WebHookEventService from '@/entities/web-hook-event/web-hook-event.service';
import WebHookEndpointService from '@/entities/web-hook-endpoint/web-hook-endpoint.service';
import ElbEventService from '@/entities/elb-event/elb-event.service';
import ElbResourceService from '@/entities/elb-resource/elb-resource.service';
import ArticleService from '@/entities/article/article.service';
import HomeService from '@/entities/home/home.service';
import CertificateService from '@/entities/certificate/certificate.service';
import WebHookConversationService from '@/entities/web-hook-conversation/web-hook-coversation.service';
import RegionService from '@/entities/region/region.service';
import HubSpotFormService from '@/entities/hub-spot-form/hub-spot-form.service';
import BatchService from '@/admin/batch/batch.service';
import TempUserService from '@/admin/temp-user/temp-user.service';
import ServerService from '@/entities/setting/server/server.service';
import AddonService from './entities/addon/addon.service';
import EmailTemplateService from './entities/email-template/email-template.service';
import DashboardService from './entities/dashboard/dashboard.service';
import ElbInvoiceService from '@/entities/elb-invoice/elb-invoice.service';
import LocationService from '@/shared/location/location.service';
import UserActivityService from '@/entities/user-activity/user-activity.service';
import JobSchedulerService from '@/admin/scheduler/job-scheduler.service';
import PaymentLinkService from '@/entities/payment-link/payment-link.service';
import EndpointService from '@/entities/end-points/end-points.service';
import OutboxService from '@/entities/outbox/outbox.service';
import NotificationService from '@/entities/notification/notification.service';
import NotificationRecipientService from '@/entities/notification/notification-recipient.service';
import VuePapaParse from 'vue-papa-parse';

import { setHttpInterceptors } from '@/shared/config/axios-interceptor';
import { utils } from '@/shared/utils';
import { TOKEN } from '@/constants';
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

import Default from '@/layouts/Default.vue';
import NoNavbar from '@/layouts/NoNavbar.vue';
import { Chart, registerables } from 'chart.js';
import EncodeService from '@/entities/encode/encode-service';
import DecodeTokenMixin from '@/shared/decode/DecodeMixin';
import GlobalSearchService from './shared/global-search/global-search.service';

Chart.register(...registerables);

Vue.component('DefaultLayout', Default);
Vue.component('NoNavbarLayout', NoNavbar);

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
// config.initFortAwesome(Vue);
Vue.use(Vue2Filters);
Vue.component('JhiItemCount', JhiItemCountComponent);
Vue.component('JhiSortIndicator', JhiSortIndicatorComponent);
Vue.component('InfiniteLoading', InfiniteLoading);

Vue.use(VueReCaptcha, { siteKey: '6LezakAaAAAAAB5_znv7bAuLOwiL8kvQsHe-OnOm' });
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard);
Vue.use(JsonViewer);
Vue.use(VuePapaParse);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const alertService = new AlertService(store);
const asyncService = new AsyncService(store);
const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const storage = new StorageService();
const accountService = new AccountService(store, translationService, storage, router);
const locationService = new LocationService(store);
const stripePlanService = new StripePlanService(store, new UpgradeService());
const encodeService = new EncodeService();
const decodeMixin = new DecodeTokenMixin(encodeService);

setHttpInterceptors(store, router);
// const acceptanceService = new AcceptanceService(store);

router.beforeEach(async (to, from, next) => {
  await decodeMixin.decode(router, to, from, next, encodeService);
  if (accountService.needsToCompleteCheckout()) {
    if (to.path.length > 0 && to.path.includes('account/checkout')) {
      next();
    } else {
      next('/account/checkout');
    }
  }

  const exp = localStorage.getItem('exp');
  if (!exp) {
    const token = localStorage.getItem(TOKEN);
    if (token && !utils.checkTokenValidity(token)) {
      localStorage.removeItem(TOKEN);
      sessionStorage.removeItem(TOKEN);
      store.commit('logout');
      next('/');
    }
  } else {
    if (!utils.checkTokenExpValidity(exp) && (parseInt(localStorage.getItem('reauthCount')) <= 5 || !localStorage.getItem('reauthCount'))) {
      store.commit('setReAuthentication', true);
    } else if (!utils.checkTokenExpValidity(exp)) {
      localStorage.removeItem('reauthCount');
      localStorage.removeItem(TOKEN);
      sessionStorage.removeItem(TOKEN);
      localStorage.removeItem('exp');
      store.commit('logout');
      next('/');
    } else if (to.path === '/'){
      // if session has authorization and root url is set to root '/' then redirect to home
      next('/product-version/apps');
    }
  }

  locationService.setLocation();

  if (!to.matched.length) {
    next('/not-found');
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    const value = await accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities);
    if (!value) {
      localStorage.setItem('requested-url', to.fullPath);
      next('/forbidden');
    } else {
      next();
    }
    // accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
    //   if (!value) {
    //     localStorage.setItem('requested-url', to.fullPath);
    //     next('/forbidden');
    //   } else {
    //     next();
    //   }
    // });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  provide: {
    loginService: () => loginService,

    auditsService: () => new AuditsService(),

    cronJobLogsService: () => new CronJobLogsService(),

    healthService: () => new HealthService(),

    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),
    alertService: () => alertService,
    asyncService: () => asyncService,
    translationService: () => translationService,
    applicationUserService: () => new ApplicationUserService(),
    elbSubscriptionService: () => new ElbSubscriptionService(),
    organizationService: () => new OrganizationService(),
    planService: () => new PlanService(),
    planFeatureService: () => new PlanFeatureService(),
    productService: () => new ProductService(),
    productVersionService: () => new ProductVersionService(),
    settingService: () => new SettingService(),
    endPointService: () => new EndpointService(),
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    registerService: () => new RegisterService(),
    activateService: () => new ActivateService(),
    apiKeyService: () => new ApiKeyService(),
    webHookVersionService: () => new WebHookVersionService(),
    webHookEventService: () => new WebHookEventService(),
    webHookEndpointService: () => new WebHookEndpointService(),
    elbEventService: () => new ElbEventService(),
    signinService: () => new SigninService(),
    resetPasswordService: () => new ResetPasswordService(),
    profileService: () => new ProfileService(),
    elbResourceService: () => new ElbResourceService(),
    articleService: () => new ArticleService(),
    homeService: () => new HomeService(),
    certificateService: () => new CertificateService(),
    webHookConversationService: () => new WebHookConversationService(),
    batchService: () => new BatchService(),
    upgradeService: () => new UpgradeService(),
    tempUserService: () => new TempUserService(),
    regionService: () => new RegionService(),
    hubSpotFormService: () => new HubSpotFormService(),
    serverService: () => new ServerService(),
    addonService: () => new AddonService(),
    emailTemplateService: () => new EmailTemplateService(),
    dashboardService: () => new DashboardService(),
    elbInvoiceService: () => new ElbInvoiceService(),
    locationService: () => locationService,
    userActivityService: () => new UserActivityService(),
    jobSchedulerService: () => new JobSchedulerService(),
    paymentLinkService: () => new PaymentLinkService(),
    stripePlanService: () => stripePlanService,
    outboxService: () => new OutboxService(),
    notificationService: () => new NotificationService(),
    notificationRecipientService: () => new NotificationRecipientService(),
    agreementService: () => new AgreementService(),
    // acceptanceService: () => acceptanceService,
    encodeService: () => new EncodeService(),
    globalSearchService: () => new GlobalSearchService(),
  },
  template: '<App/>',
  vuetify,
  router,
  i18n,
  store,
});
