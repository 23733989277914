import { Authority } from '@/shared/security/authority';

const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiAuditsComponent = () => import('@/admin/audits/audits.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const BatchComponent = () => import('@/admin/batch/batch.vue');
const TempUserComponent = () => import('@/admin/temp-user/temp-user.vue');
const TempUserUpdate = () => import('@/admin/temp-user/temp-user-details.vue');
const AuditLogsComponent = () => import('@/admin/audits/logs.vue');
const ImportUserComponent = () => import('@/admin/import-user/import-user.vue');
const ImportUserUpdate = () => import('@/admin/import-user/import-user-details.vue');
const JobScheduler = () => import('@/admin/scheduler/job-scheduler.vue');
const JobSchedulerDetails = () => import('@/admin/scheduler/job-scheduler-details.vue');
const JobSchedulerUpdate = () => import('@/admin/scheduler/job-scheduler-update.vue');
const CronJobLogsComponent = () => import('@/admin/cron-job/logs.vue');

export default [
  {
    path: '/admin/batch',
    name: 'BatchComponent',
    component: BatchComponent,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/admin/audits/logs',
    name: 'AuditLogsComponent',
    component: AuditLogsComponent,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/admin/cron-jobs/logs',
    name: 'CronJobLogsComponent',
    component: CronJobLogsComponent,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/admin/temp-user',
    name: 'TempUserComponent',
    component: TempUserComponent,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/admin/temp-user/:id/edit',
    name: 'TempUserEdit',
    component: TempUserUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },// TempUserEdit
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/audits',
    name: 'JhiAuditsComponent',
    component: JhiAuditsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/jhi-health',
    name: 'JhiHealthComponent',
    component: JhiHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'JhiLogsComponent',
    component: JhiLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/jhi-metrics',
    name: 'JhiMetricsComponent',
    component: JhiMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/jhi-configuration',
    name: 'JhiConfigurationComponent',
    component: JhiConfigurationComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/import-user',
    name: 'ImportUserComponent',
    component: ImportUserComponent,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/admin/import-user/:id/edit',
    name: 'ImportUserEdit',
    component: ImportUserUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/admin/job-scheduler',
    name: 'JobScheduler',
    component: JobScheduler,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/admin/job-scheduler/new',
    name: 'JobSchedulerCreate',
    component: JobSchedulerUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/admin/job-scheduler/:id/edit',
    name: 'JobSchedulerEdit',
    component: JobSchedulerUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/admin/job-scheduler/:id/view',
    name: 'JobSchedulerDetails',
    component: JobSchedulerDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
];
